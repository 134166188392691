var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in bg-gray-3"},[_c('a-row',{staticStyle:{"margin-top":"80px"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form pt-20 mt-0",attrs:{"span":24,"md":8,"lg":8,"xl":{span: 8, offset: 0}}},[_c('a-card',{staticClass:"card-info px-0 mx-0 pt-20",attrs:{"bordered":false}},[_c('a-row',{attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"px-25",attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center"},[_c('a-col',{attrs:{"lg":24}},[_c('img',{staticStyle:{"border-radius":"20px"},attrs:{"src":require("../../public/images/icon.png"),"alt":"","width":"80px","height":"80px"}})])],1),_c('h3',{staticClass:"mb-0 mt-10 text-center"},[_vm._v("Create Account!")]),_c('p',{staticClass:"text-muted text-center mt-0 pt-0"},[_vm._v("Enter your details to create account")]),(_vm.error.show)?_c('a-alert',{attrs:{"message":_vm.error.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"First Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'firstName',
								{ rules: [{ required: true, message: 'Please input your first name!' }] } ]),expression:"[\n\t\t\t\t\t\t\t\t'firstName',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your first name!' }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"First Name"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Last Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'lastName',
								{ rules: [{ required: true, message: 'Please input your last name!' }] } ]),expression:"[\n\t\t\t\t\t\t\t\t'lastName',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your last name!' }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Last Name"}})],1),_c('a-input-group',{},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mt-5",attrs:{"span":24}},[_c('label',{staticStyle:{"font-weight":"600","font-size":"13px","color":"#141414"}},[_vm._v("Email / Phone Number")])]),_c('a-col',{staticClass:"w-100 pr-0 mr-0 mt-5",attrs:{"span":5}},[_c('a-form-item',{staticClass:"mb-10 w-100",attrs:{"label":"","colon":false}},[_c('vue-country-code',{staticStyle:{"height":"40px","width":"100%","margin-top":"4px"},attrs:{"preferredCountries":['tz', 'ke', 'ug', 'za'],"defaultCountry":"tz"},on:{"onSelect":_vm.onSelectCountry}})],1)],1),_c('a-col',{staticClass:"ml-0 pl-1 mt-5",attrs:{"span":19}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
												'username',
												{ rules: [{ required: true, message: 'Please input your username!' }] } ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t\t'username',\n\t\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your username!' }] },\n\t\t\t\t\t\t\t\t\t\t\t\t]"}],staticStyle:{"margin-top":"4px","width":"100%"},attrs:{"placeholder":"Email or Phone number"}})],1)],1)],1)],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'password',
								{ rules: [{ required: true, message: 'Please input your password!' }] } ]),expression:"[\n\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Confirm Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'passwordConfirmation',
								{ rules: [{ required: true, message: 'Please input your password!' }] } ]),expression:"[\n\t\t\t\t\t\t\t\t'passwordConfirmation',\n\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Confrirm Password"}})],1),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"id":"signinBtn","loading":_vm.isLoading,"type":"silabu","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1),_c('a-row',{staticClass:"pt-10 pb-10",attrs:{"gutters":24}},[_c('a-col',{staticClass:"text-center pb-5",attrs:{"span":24}},[_vm._v(" Already have an account? "),_c('a',{staticStyle:{"text-decoration":"none !important","color":"#632163"},attrs:{"href":"/sign-in"}},[_c('strong',[_vm._v("Sign in")])])])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }