<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="8" :lg="8" :xl="{span: 8, offset: 0}" class="col-form pt-20 mt-0">
				
				<a-card :bordered="false" class="card-info px-0 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						
						<a-col :span="24" :md="24" :lg="24" class="px-25">
							<a-row class="text-center">
								<a-col :lg="24">
									<img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;"> 
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-10 text-center">Create Account!</h3>
							<p class="text-muted text-center mt-0 pt-0">Enter your details to create account</p>

							<a-alert
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true">

								<a-form-item class="mb-10" label="First Name" :colon="false">
									<a-input
									v-decorator="[
									'firstName',
									{ rules: [{ required: true, message: 'Please input your first name!' }] },
									]" type="text" placeholder="First Name" />
								</a-form-item>

								<a-form-item class="mb-10" label="Last Name" :colon="false">
									<a-input
									v-decorator="[
									'lastName',
									{ rules: [{ required: true, message: 'Please input your last name!' }] },
									]" type="text" placeholder="Last Name" />
								</a-form-item>

								<a-input-group class="">
									<a-row :gutter="24">
										<a-col :span="24" class="mt-5">
											<label style="font-weight: 600; font-size: 13px; color: #141414;">Email / Phone Number</label>
										</a-col>
										<a-col :span="5" class="w-100 pr-0 mr-0 mt-5">
											<a-form-item class="mb-10 w-100" label="" :colon="false">
												<vue-country-code
													style="height: 40px; width: 100%; margin-top: 4px;"
													@onSelect="onSelectCountry"
													:preferredCountries="['tz', 'ke', 'ug', 'za']"
													defaultCountry="tz">
												</vue-country-code>
											</a-form-item>
										</a-col>
										<a-col :span="19" class="ml-0 pl-1 mt-5">
											<a-form-item class="mb-10" :colon="false">
												<a-input 
													style="margin-top: 4px; width: 100%;"
													v-decorator="[
													'username',
													{ rules: [{ required: true, message: 'Please input your username!' }] },
													]" placeholder="Email or Phone number" />
											</a-form-item>
										</a-col>
									</a-row>
									
								</a-input-group>
								
								<a-form-item class="mb-10" label="Password" :colon="false">
									<a-input
									v-decorator="[
									'password',
									{ rules: [{ required: true, message: 'Please input your password!' }] },
									]" type="password" placeholder="Password" />
								</a-form-item>

								<a-form-item class="mb-10" label="Confirm Password" :colon="false">
									<a-input
									v-decorator="[
									'passwordConfirmation',
									{ rules: [{ required: true, message: 'Please input your password!' }] },
									]" type="password" placeholder="Confrirm Password" />
								</a-form-item>

								<a-form-item class="mt-4">
									<a-button id="signinBtn" :loading="isLoading" type="silabu" block html-type="submit" class="login-form-button mt-10 text-white" >
										SIGN UP
									</a-button>
								</a-form-item>


								<a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="" style="text-decoration: none !important; color: #632163;" :href="`/sign-in`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row>

							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				countryCode: '255',

				isLoading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
		},
		methods: {

			onSelectCountry({name, iso2, dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const firstName = values.firstName;
						const lastName = values.lastName;
						const constUsername = values.username;
						let email = '';
						let mobile = '';
						const password = values.password;
						const passwordConfirmation = values.passwordConfirmation;

						// console.log(constUsername.substring(0, 4));

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.countryCode) && !constUsername.includes("+")) {
								mobile = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.countryCode) && constUsername.includes("+")) {
								mobile =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								mobile = "+" + this.countryCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								mobile = "+" + this.countryCode + "" + constUsername
							}

							else {
								mobile = constUsername;
							}

						}else{
							email = constUsername;
						}

						let url = `${this.$BACKEND_URL}/auth/register/socialite`;
						
						this.$AXIOS.post(url, { firstName, lastName, mobile, email, password, passwordConfirmation}).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {
								// await localStorage.setItem("agent_token", response.data._id)
								await localStorage.setItem("agent_details", JSON.stringify(response.data.user))

								this.notify("success", response.data.message)
								
								this.isLoading = false;

								this.$router.push(`/verify-account`);
							}
							this.isLoading = false
						}).catch((error) => {
							console.log(error)
							this.isLoading = false;
							
							this.error.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},

			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
</style>
<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->
